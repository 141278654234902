#projects {
  padding-bottom: 2em;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #000; 
  color: #fff;
  text-align: center;
  overflow-y: auto; 
  height: 85vh; 
}

#projects h1 {
  font-family: 'Lato-Black', Arial, sans-serif;
  font-size: calc(20px + 6vw);

}

#projects h2 {
  font-family: 'SourceSans3', Arial, sans-serif;
  font-size: calc(10px + 2vw);
  margin-top: 2em;
  margin-bottom: 1em;
}

#projects h3 {
  font-family: 'SourceSans3', Arial, sans-serif;
  font-size: calc(8px + 1.5vw);
  margin-bottom: 0%;
  
}

#projects h4 {
  font-family: 'Lato-Black', Arial, sans-serif;
  font-size: 1em;
}
#projects p {
  font-family: 'SourceSans3', Arial, sans-serif;
  font-size: calc(5px + 1vw);
  padding-left: 5%;
  padding-right: 5%;
}

#projects a i {
  margin-bottom: 5%;
}

/* Container for project cards */
.projects-container {
  display: grid;
  gap: 5em;
}

.project-card {
  position: relative;
  background-color: #2e2e2e;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s;
  width: 100%; 
  aspect-ratio: 16 / 10.5882353; 
}

.project-card:hover {
  transform: scale(1.05);
}

.project-image {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9; 

}

.project-details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15%; 
  background-color: #292929; 
  color: rgb(255, 255, 255); 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
}

.project-title {
  margin: 0;
  font-family: 'SourceSans3', Arial, sans-serif;
  font-size: 1em;
  font-weight: 700;
  text-align: center;
}


.overlay {
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.expanded-project {
  position: relative;
  width: 75%;
  max-height: 80%;
  max-width: 900px;
  background-color: #292929;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  aspect-ratio: 16 / 10.5;
}

.expanded-project .project-image {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  
}

.expanded-project .project-details {
  background-color: #292929;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  
}

.expanded-project .project-title {
  margin-top: 5px;
  font-size: 1.5em;
  padding-bottom: 0;
  margin-bottom: 0;
}

.expanded-project .arrow-down{
  margin-top: -5px;
  padding: 0;
}

.expanded-project .project-description {
  font-size: 1.2em;
  line-height: 1.5;
  text-align: center;
}

.expanded-project .github-button {
  font-size: 1.2em;
  color: #fff;
  text-decoration: none;
  background-color: #333;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

/* Desktop styles */
@media (min-width: 769px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
  }
  .project-title {
    font-size: 1.2em;
  }

  .project-description {
    font-size: 0.8em;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  #projects {
    padding-left: 10%;
    padding-right: 10%;
  }

  #projects p {
    font-size: calc(5px + 1.7vw);
  }

  #projects h3 {
    font-size: calc(10px + 3vw);
  }

  .projects-container {
    grid-template-columns: 1fr;
  }

  .expanded-project {
    width: 80%;
    aspect-ratio: 16 / 18;
  }

  .expanded-project .project-image {
    aspect-ratio: 16 / 9;
  }

  .expanded-project .project-details {
    padding: 10px;
    padding-bottom: 54%;
  }

  .expanded-project .project-title {
    font-size: 1.5em;
  }

  .expanded-project .project-description {
    font-size: 1em;
  }

  .project-card {
    margin-bottom: 1em;
    width: 100%;
  }
  .project-title {
    font-size: 1.2em;
  }

  .project-description {
    font-size: 0.75em;
  }
}
