
.container {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "text image";
  height: 85vh;
  overflow-y: auto;
}

.mobile-container {
  position: relative;
  height: 85vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: auto; 
}
#about {
  grid-area: text;
  background-color: #020202;
  padding: 2em;
  color: #fff;
  margin-left: 10%;
  overflow-y: auto; 
}

.mobile-container #about {
  background-color: transparent;
  padding: 1em;
}

.about-background-image {
  margin-left: 20%;
  grid-area: image;
  background-image: url('/public/assets/background_images/about_flower.jpg');
  background-size: cover;
  background-position: center;
  transform: scale(0.8);
  transform-origin: top left;
  display: block;
}

.mobile-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/public/assets/background_images/about_flower.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  z-index: 1;
}

.about-content {
  position: relative;
  z-index: 2;
  color: #fff;
}

.about-greeting h1 {
  font-family: 'Lato-Black', Arial, sans-serif;
  font-size: 4em;
  margin: 0;
  line-height: 1;
}

.about-description p {
  font-size: 1.2em;
  line-height: 1.5;
}

/* Mobile */
@media (max-width: 768px) {
  .container {
    display: block;
  }

  .about-background-image {
    display: none;
  }

  #about {
    background-color: transparent; 
    margin-left: 0;
  }

  .about-greeting h1 {
    font-size: 2em;
  }
  
  .about-description p {
    font-size: 1em;
    
  }
}

