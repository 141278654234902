.contact-section {
    position: relative;
    padding: 2em;
    background-color: #020202;
    color: #fff;
    overflow-y: hidden; 
    height: 85vh;  
  }
  
  .contact-background-image {
    position: absolute;
    right: 0;
    bottom: -10%;
    width: 70%;
    height: 70%;
    background-image: url('/public/assets/background_images/contact_flower.jpg');
    background-size: cover; 
    background-position: center; 
    background-size: contain; 
    background-repeat: no-repeat; 
    z-index: 1; 
    opacity: 1; 
    overflow-y: hidden;
  }
  
  .contact-container {
    margin-left: 8%;
    display: flex;
    flex-direction: row;
    max-width: 1200px;
    width: 100%;
    height: 100%; 
    align-items: center;
    z-index: 2;
    
  }
  
  .contact-form-container {
    
    max-width: 600px;
    width: 100%;
    height: 100%; 
    z-index: 2;
    
  }
  
  .contact-form-container h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
    z-index: 2;
    
  }
  
  .contact-form-container h1 {
    font-size: 2.5em;
    margin-bottom: 1em;
    z-index: 2;
    
  }
  
  .contact-form-container p {
    font-size: 1em;
    margin-bottom: 2em;
    z-index: 2;
    
  }
  
  .contact-form-container form {
    display: flex;
    flex-direction: column;
    
  }
  
  .contact-form-container input
  {
    padding: 0.5em;
    margin-bottom: 1em;
    border: 1px solid #fff;
    border-radius: 5px;
    font-size: 1em;
    width: 90%;
    
  }
  .contact-form-container textarea {
    padding: 0.5em;
    margin-bottom: 1em;
    border: 1px solid #fff;
    border-radius: 5px;
    font-size: 1em;
    width: 90.5%;
    height: 100px;
  }
  
  .contact-form-container button {
    padding: 0.75em 1em;
    border: none;
    border-radius: 5px;
    background-color: #fff;
    color: #555;
    font-size: 1em;
    cursor: pointer;
    margin-top: 1em;
  }
  
  .contact-form-container button:hover,
  .contact-form-container .schedule-meeting-button:hover {
    background-color: #555;
    color: #fff;
  }
  
  .contact-form-container .schedule-meeting-button:hover {
    background-color: #555;
    color: #fff;
  }

  .contact-form-container contact-buttons {
    margin-top: 10%;
    font-size: 2em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .contact-form-container contact-buttons a {
    color: #fff;
    margin-right: 1em;
  }

  .contact-form-container contact-buttons a:hover {
    color: #ccc;
    transition: background-color 0.5s ease-in-out; 
  }

  @media (max-width: 768px) {
    .contact-section {
      height: 85vh;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10%; 
      padding-right: 10%;
      padding-top: 0;
      
    }

    .contact-background-image {
      bottom: 5%;
    }
  
    .contact-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 0;
      margin-left: 0; 
    }
  
    .contact-form-container {
      margin: 0;
      width: 100%;
      padding: 0 10%; 
      padding-top: 0;
    }

    .contact-form-container h1 {
      font-size: 1.5em;
      margin-bottom: 0.3em; 
    }
    
    .contact-form-container p {
      font-size: 0.5em;
    }
  
    .contact-form-container input
    {
      width: 90%; 

    }
    .contact-form-container textarea {
      width: 90%; 
      height: 60px;
    }

    .contact-form-container input
    {
      font-size: 0.7em;
    }

    .contact-form-container button {
      font-size: 0.75em;
    }

    .contact-form-container contact-buttons {
      margin-top: 30%;
      font-size: 1.5em;
    }
    .contact-form-container contact-buttons a {
      color: #fff;
      margin-right: 0.2em;
    }
  }