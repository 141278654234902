/* Global styles */
#home {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 85vh; 
  background-color: #020202; 
  box-sizing: border-box; 
  position: relative;
  overflow: hidden;
  padding-left: 0%; 
  margin-left: 11%;
}

.home-name {
  color: #fff;
  font-size: 4em;
  font-family: 'Lato-Black', Arial, sans-serif;
  text-align: left; 
  z-index: 2;
  margin-top: 1%; 
}

.home-description {
  color: #fff;
  font-size: 1.5em;
  font-family: 'Roboto-Medium', Arial, sans-serif;
  text-align: left; 
  z-index: 2;
  margin-top: 2%; 
  margin-left: 0.5%;
}

.home-background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('/public/assets/background_images/home_flower.jpg');
  background-size: cover; 
  background-position: center; 
  z-index: 1; 
  opacity: 1; 
  
}

.home-typing-animation {
  color: #fff;
  font-family: 'Roboto-Medium', Arial, sans-serif;
  text-align: left; 
  font-size: 1.3em;
  z-index: 2;
  margin-top: 0%; 
  margin-left: 0.7%;
}

/* Add this CSS to your stylesheet */
.btn-projects {
  font-family: 'Roboto-Medium', Arial, sans-serif;
  display: inline-block;
  padding: 10px 20px;
  font-size: 2em;
  font-weight: bold;
  color: #fff;
  background-color: transparent; /* Visible pinkish color */
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  z-index: 10; /* Ensure it's on top of other elements */
  position: relative; /* Make sure it stays in the flow */
}



/* Keyframes for the top arrow's bounce */
@keyframes bounceUp {
  0%, 100% {
    transform: translateY(0); /* Start and end at the original position */
  }
  50% {
    transform: translateY(-10px); /* Move up by 10px at the midpoint */
  }
}

/* Keyframes for the bottom arrow's bounce (reverse direction) */
@keyframes bounceDown {
  0%, 100% {
    transform: translateY(0); /* Start and end at the original position */
  }
  50% {
    transform: translateY(10px); /* Move down by 10px at the midpoint */
  }
}

/* Styling for the up arrow */
.arrow-up {
  padding-top: 1em;
  font-size: 30px;
  color: #ffffff; /* White color */
  display: block;
  text-align: center;
  z-index: 11; /* Ensure it stays on top */
  position: relative;
  animation: bounceUp 1.5s infinite ease-in-out; /* Apply bounce animation */
}

/* Styling for the down arrow */
.arrow-down {
  padding-top: 2.5em;
  padding-bottom: 1em;
  font-size: 30px;
  color: #ffffff;
  display: block;
  text-align: center;
  z-index: 11;
  position: relative;
  animation: bounceDown 1.5s infinite ease-in-out; /* Apply reverse bounce animation */
}



.btn-projects:hover {
  background-color: #90d7fb;
}



.home-contact-icons {
  display: flex;
  z-index: 2;
  width: 80%;
  position: absolute;
  bottom: 5%;
}

.home-contact-icons a {
  color: #fff;
  font-size: 2em;
  text-decoration: none;
  box-sizing: border-box; 
  margin-top: 5%;
  margin-right: 2%;
  padding: 2%;
}

.home-contact-icons a:hover {
  color: #ccc;
  transition: background-color 0.5s ease-in-out; 
  bottom: 0%;
  flex-direction: row; 
}

/* Mobile styles */
@media (max-width: 768px) {
  #home {
    padding-left: 0%; 
    margin-left: 0%;
  }
  
  .home-background-image {
    background-position: 80% center;
    left: 10%;
  }
  
  .home-name {
    padding-left: 6%;
    font-size: 3em;
  }
  
  .home-description {
    padding-left: 6.5%;
    font-size: 1em;
    margin-top: 3%;
  }
  
  .home-typing-animation {
    padding-left: 6.5%;
    font-size: 1em;
    margin-top: 0%;
  }
  
  .home-contact-icons {
    bottom: 0%;
    padding-left: 3%;
    flex-direction: row; 
    align-items: flex-start; 
  }
  
  .home-contact-icons a {
    margin-top: 5%;
    margin-right: 2%;
    padding-right: 2%;
  }
}
