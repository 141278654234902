/* General Styles */
@import '~@fortawesome/fontawesome-free/css/all.css';

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../public/assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Lato-Black';
  src: url('../../public/assets/fonts/Lato-Black.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../../public/assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'RobotoCondensed-Bold';
  src: url('../../public/assets/fonts/RobotoCondensed-Bold.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'SourceSans3';
  src: url('../../public/assets/fonts/SourceSans3-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
}

body {
  font-family: 'Roboto-Regular', Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #020202;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  
}

/*==================================================Header==================================================
=                                                                                                          =
=                                                                                                          =
===========================================================================================================*/

/* Header Styles */
nav {
  top: 0;
  width: 100%; 
  height: 15%; 
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 1000; 
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex; 
  align-items: center; 
  width: 100%; 
  justify-content: space-between; 
}

nav ul li {
  margin: 0; 
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  border-radius: 5px; 
  padding: 10px; 
  background-color: #020202; 
  transition: background-color 0.5s ease-in-out; 
}

nav ul li a:hover {
  background-color: #f7f6f7; 
  color: #000; 
  border-radius: 5px; 
}

/* Mobile Styles */
@media (max-width: 768px) {
  nav {
    margin-left: 0;
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.85em;
  }
  
  nav ul {
    flex-direction: row; 
    justify-content: space-between; 
  }
  
  nav ul li {
    margin: 0; 
  }
  
  nav ul li a {
    padding: 10px; 
  }
  
  nav ul li a:hover {
    background-color: #f7f6f7; 
  }
}

/* PC View Styles */
@media (min-width: 769px) {
  nav {
    box-sizing: border-box; 
    margin-left: 10%;
    width: 100%; /* Adjust width to prevent overflow */
  }
  
  nav ul {
    justify-content: flex-start; 
  }
  
  nav ul li {
    margin-right: 7px; 
  }
}


/*==================================================Footer==================================================
=                                                                                                          =
=                                                                                                          =
===========================================================================================================*/

/* Footer Styles */
.footer {
  position: fixed;
  bottom: 0%;
  width: 100%;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 0;
  font-family: 'Roboto-Regular', Arial, sans-serif;
  z-index: 1000;
}

.footer p {
  margin: 0;
  font-size: 0.75em;
}

